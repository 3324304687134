.home-navbar {
    width: 100%;
    display: flex;
    z-index: 100;
    align-items: center;
    flex-direction: column;
}


.home-navbar-interactive {
    width: 100%;
    display: flex;
    position: relative;
    max-width: 1324px;
    align-items: center;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
}

.home-branding {
    gap: var(--dl-space-space-unit);
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}


.home-text01 {
    color: rgba(18, 40, 33, 1);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
}

.home-desktop-menu {
    display: flex;
}

.home-text {
    color: rgba(18, 40, 33, 1);
    font-size: 24px;
    line-height: 36px;
}

.home-icon {
    height: 40px;
}

.home-links {
    gap: var(--dl-space-space-threeunits);
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.home-button {
    color: rgb(18, 40, 33);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: transparent;
}

.home-button01 {
    color: rgb(18, 40, 33);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: 0px;
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: transparent;
}

.home-button02 {
    color: rgb(18, 40, 33);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
    padding-top: var(--dl-space-space-halfunit);
    border-color: rgba(18, 40, 33, 1);
    padding-left: var(--dl-space-space-threeunits);
    border-radius: 0px;
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-halfunit);
    background-color: transparent;
    border-left-width: 1px;
}

.home-globe {
    width: 20px;
}

.language-menu {
    position: absolute;
    display: none;
    flex-direction: column;
    border: 1px solid;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
}

.language-menu.active {
    display: flex;
}

.home-burger-menu {
    cursor: pointer;
    display: none;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.home-hamburger {
    width: 100px;
    object-fit: cover;
}

.home-mobile-menu {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    z-index: 100;
    position: fixed;
    transform: translateX(100%);
    transition: 0.5s;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-threeunits);
    justify-content: flex-start;
    background-color: #fff;
}

.home-mobile-menu.active {
    transform: translateX(0);
}

.home-top {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--dl-space-space-threeunits);
    justify-content: space-between;
}

.home-branding1 {
    gap: var(--dl-space-space-unit);
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}


.home-text04 {
    color: rgba(18, 40, 33, 1);
    font-size: 24px;
    line-height: 36px;
}

.home-icon02 {
    width: 7px;
    height: 7px;
}

.home-menu-close {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-icon04 {
    width: 34px;
    height: 34px;
}

.home-links1 {
    gap: var(--dl-space-space-unit);
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.home-button03 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
    border-radius: 28px;
    background-color: rgb(18, 40, 33);
}

.home-button04 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
    border-radius: 28px;
    background-color: rgb(18, 40, 33);
}

.menu-divider {
    border-top: 1px solid rgba(18, 40, 33, 1);
    width: 100%;
    margin: 0;
}

.home-button05 {
    color: var(--dl-color-gray-white);
    font-size: 18px;
    font-family: Lato;
    line-height: 27px;
    border-radius: 28px;
    background-color: rgb(18, 40, 33);
}

.home-social-bar {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-threeunits);
    margin-top: auto;
    justify-content: flex-start;
}

.home-icon06 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

.home-icon08 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

.home-icon10 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

.home-icon12 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    margin-right: var(--dl-space-space-twounits);
}

.home-icon14 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
}