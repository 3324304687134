h1,
h2,
h3 {
    color: #333;
    margin-top: 20px;
}

p {
    margin: 10px 0;
}

ul {
    margin: 10px 0;
}

.terms-privacy-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.terms-privacy-hero {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
    display: flex;
    position: relative;
    max-width: var(--dl-size-size-maxwidth);
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
}

.terms-privacy-content {
    gap: var(--dl-space-space-unit);
    width: auto;
    display: flex;
    position: relative;
    max-width: 1280px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
}